import React from 'react';
import { Global } from '@emotion/react';

const GlobalStyle = () => {
  return (
    <Global
      styles={() => [
        {
          '@font-face': {
            fontFamily: 'Muli',
            src: `local(Muli-Regular), url('https://connected-care-common-assets.s3.eu-central-1.amazonaws.com/fonts/Muli/Muli-Regular.ttf') format("truetype")`,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            fontDisplay: 'swap',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Muli',
            src: `local(Muli-Bold), url('https://connected-care-common-assets.s3.eu-central-1.amazonaws.com/fonts/Muli/Muli-Bold.ttf') format("truetype")`,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            fontDisplay: 'swap',
          },
        },
      ]}
    />
  );
};

export default GlobalStyle;
