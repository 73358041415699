/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, jsx } from 'theme-ui';
import GlobalStyle from '../GlobalStyle';
import SEO from './SEO';

const Layout: React.FC = ({ children }) => {
  return (
    <main>
      <SEO></SEO>
      <GlobalStyle />
      <Box
        sx={{
          width: '100%',
          my: 80,
          maxWidth: '1024px',
          minWidth: '375px',
          mx: 'auto',
          px: [3, 9],
        }}
      >
        {children}
      </Box>
    </main>
  );
};

export default Layout;
