const theme = {
  breakpoints: ['40em', '64em'],
  colors: {
    science: '#1f7bff',
    lunar: '#6d8dae',
    navy: '#042949',
    white: 'white',
    silver: '#e8eff7',
    clouds: '#e1eeff',
  },
  shadows: {
    boxShadow: '0 4px 12px 0 #c2ddff',
  },
  fonts: {
    body: 'Muli',
    heading: 'Muli',
    monospace: 'Muli',
  },
  fontWeights: {
    body: 500,
    heading: 600,
    bold: 700,
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 32, 36, 40, 44, 48, 64, 96],
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40],
  sizes: [0, 4, 8, 12, 16, 20, 24, 32, 48, 64, 96],
  text: {
    heading: {
      fontWeight: 'heading',
      boxSizing: 'border-box',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      color: 'navy',
      fontSize: 2,
    },
    h1: {
      variant: 'text.heading',
      fontSize: 5,
      lineHeight: '32px',
      marginBottom: 5,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 3,
      lineHeight: '28px',
    },
  },
  forms: {
    input: {
      fontFamily: 'body',
    },
  },
};

export default theme;
