import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useI18next } from 'gatsby-plugin-react-i18next';

type Props = {
  title?: string;
};
const SEO: FC<Props> = ({ children, title = 'Help Center' }) => {
  const { languages, language, originalPath, defaultLanguage, siteUrl = '' } = useI18next();

  const createUrlWithLang = (lng: string) => {
    const url = `${siteUrl}${lng === defaultLanguage ? '' : `/${lng}`}${originalPath}`;
    return url.endsWith('/') ? url : `${url}/`;
  };

  return (
    <Helmet htmlAttributes={{ lang: language }} title={`${title} - ConnectedCare`}>
      {children}
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {languages.map((lng) => (
        <link rel="alternate" key={lng} href={createUrlWithLang(lng)} hrefLang={lng} />
      ))}
      <link rel="alternate" href={createUrlWithLang(defaultLanguage)} hrefLang="x-default" />

      <meta itemProp="name" content={`${title} - ConnectedCare`} />
    </Helmet>
  );
};

export default SEO;
